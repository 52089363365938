import React from "react";
import { Modal, Button } from "react-bootstrap";
//import 'bootstrap/dist/css/bootstrap.min.css';

function ModalContainer ({show, handleClose, days, details}){

    const modalTitle = days;
    const modalBody = details;
    
    return(
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {modalBody}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Tutup
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export  default ModalContainer;